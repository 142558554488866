import { OparetaTable } from '@/common/OparetaTable';
import { Box, Button, Checkbox, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { reassignmentListFetch } from './ReassignmentListSlice';
import { useEffect } from 'react';
import { getSortOrder } from '@/common';
import dayjs from 'dayjs';
import { Clear, Repeat } from '@mui/icons-material';

export const ReassignmentsTableView = () => {
  const dispatch = useDispatch();
  const { reassignmentList, isLoading, error, lastFilter } = useSelector(
    (state) => {
      return state.reassignmentList;
    }
  );

  useEffect(() => {
    dispatch(reassignmentListFetch({}));
    hasReassignments();
  }, []);

  const w = (callback) => (param) => callback(param.row);

  const columns = [
    {
      field: 'isChecked',
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      flex: 0.2,
      sx: { padding: '0px' },
      sortingOrder: false,
      filterable: false,
      renderCell: w((reassignment) => {
        return <Checkbox size="small" checked={false} onChange={(e) => {}} />;
      }),
    },
    {
      headerName: 'Agent Name',
      field: 'agent.agentName',
      minWidth: 200,
      valueGetter: w((reassignment) => reassignment.agent?.agentName),
      sortingOrder: getSortOrder(),
      filterable: false,
    },
    {
      headerName: 'Agent Number',
      minWidth: 200,
      field: 'agentNumber',
      valueGetter: w((reassignment) => reassignment.agent?.agentNumber),
      filterable: false,
    },
    {
      headerName: 'Old Territory',
      minWidth: 200,
      field: 'oldTerritory',
      valueGetter: w((reassignment) => {
        let oldTerritoryName = '';
        const noTerritories = reassignment.oldFieldAgent?.territories?.length || 0;
        const names = [];
        for (let i = 0; i < noTerritories; i++) {
          const territory = reassignment.oldFieldAgent.territories[i];
          const name = territory.location.name;
          names.push(name);
        }

        if (names.length > 0) {
          oldTerritoryName = names.join(' | ');
        } else {
          oldTerritoryName = '...';
        }

        return oldTerritoryName;
      }),
      filterable: false,
    },
    {
      headerName: 'Field Team',
      minWidth: 200,
      field: 'oldFieldAgent.name',
      valueGetter: w((reassignment) => reassignment.oldFieldAgent?.name),
      sortingOrder: getSortOrder(),
      filterable: false,
    },
    {
      headerName: 'New Field Team',
      minWidth: 200,
      field: 'newTerritory.fieldAgent.name',
      valueGetter: w(
        (reassignment) => reassignment.newTerritory?.fieldAgent?.name
      ),
      filterable: false,
    },
    {
      headerName: 'New Territory',
      minWidth: 200,
      field: 'newTerritory.location.name',
      valueGetter: w(
        (reassignment) => reassignment.newTerritory?.location?.name
      ),
      filterable: false,
    },
    {
      headerName: 'Time in weeks',
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      field: 'timeInWeeks',
      valueGetter: w((reassignment) => {
        const today = dayjs();
        const addedAt = dayjs(reassignment.addedToNewTerritoryAt);
        const timeInWeeks = today.diff(addedAt, 'week');
        return timeInWeeks;
      }),
      filterable: false,
    },
    {
      headerName: 'Action',
      minWidth: 250,
      field: 'action',
      filterable: false,
      renderCell: w((reassignment) => {
        return (
          <Grid
            container
            spacing={4}
            sx={{
              alignItems: 'center',
            }}
          >
            <Grid item>
              <Button variant="contained" size="small">
                Reassign
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="text"
                endIcon={<Clear fontSize="small" />}
                sx={{
                  color: 'black',
                }}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        );
      }),
    },
  ];

  const hasReassignments = () => {
    return !!reassignmentList && reassignmentList.length > 0;
  };

  return (
    <>
      <Grid>
        {!hasReassignments() && (
          <Box
            sx={{
              backgroundColor: 'white',
              border: '0.5px solid rgba(0,0,0,0.2)',
              borderRadius: '1rem',
              padding: '2rem',
              height: '65vh',
            }}
          >
            No pending reassignments to show
          </Box>
        )}

        {hasReassignments() && (
          <OparetaTable
            getRowId={(reassignment) => reassignment.uuid}
            rows={reassignmentList ?? []}
            columns={columns}
            onExportClick={() => {
              console.log(`Exporting data`);
            }}
            loading={isLoading}
            page={0}
            ActionButtons={({ model, setModel, rows }) => {
              return (
                <>
                  <Button size="small" startIcon={<Repeat fontSize="small" />}>
                    Reassign
                  </Button>
                  <Button size="small" startIcon={<Clear fontSize="small" />}>
                    Clear
                  </Button>
                </>
              );
            }}
          />
        )}
      </Grid>
    </>
  );
};
